.floating-whatsapp {
    position: fixed;
    left: 15px;
    bottom: 15px;
    z-index: 996;
    background: rgba(37, 211, 102, 1);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
    &:hover {
        background: rgba(22, 126, 61, 1);
        color: rgba(255, 255, 255, 1);
    }
    &.active {
        visibility: visible;
        opacity: 1;
        bottom: 60px;
    }
    i {
        font-size: 28px;
        color: rgba(255, 255, 255, 1);
        line-height: 0;
    }
}
